import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { PageProps } from '@app/utils/local-types'
import { appPaths } from 'utils/path'
import { Query } from '@root/src/data/graphql/graphql-types'
import React from 'react'
import { MapPage } from './component/map-page.component'

export interface UnitsTemplateProps {
  slug?: string
}

const UnitsClientTemplate: React.FunctionComponent<PageProps<
  UnitsTemplateProps,
  Query
>> = props => {
  return (
    <ClientDoctorSelectContext.Provider
      value={{ clientUrl: appPaths.units.path.clientUrl, doctorUrl: appPaths.units.path.doctorUrl }}
    >
      <IndexLayout location={props.location}>
        <MapPage location={props.location} />
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default UnitsClientTemplate
