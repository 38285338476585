import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import {
  GrayLightBackground,
  NeutralBackground
} from '@root/src/components/atm.background/background.styled'
import { getListJsonLd } from '@root/src/components/legacy/mol.seo/build-list-json-ld'
import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import { scrollToTopAnimated } from '@root/src/components/org.footer/components/go-to-top-button.component'
import TitleWithBreadcrumbRow from '@root/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import { getUniqueFacilities } from '@root/src/modules/unit/unit.utils'
import { appPaths, getCanonicalUrl, getPathUrl, getUnitDetailPageUrl } from 'utils/path'
import { isDoctorUrl, normalizeUrl } from 'utils/url'
import {
  Body,
  Button,
  Col,
  Grid,
  H2,
  Row,
  Separator,
  SeparatorWV,
  //SeparatorW,
  // BodyW,
  // DivC,
  // DivWR,
  // DivTW,
  // DivCont,
  // H2w,
  // DivWR1
} from 'atomic'
import { getDistanceFromLatLonInKm } from 'atomic/legacy/atm.map/utils/map-utils'
import { EventTimestampContainer } from 'atomic/legacy/obj.abstract-container'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useMatchMediaChanged } from 'atomic/legacy/obj.match-media'
import { Modal } from 'atomic/legacy/obj.modal'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { isDesktopScreenSize } from 'utils/browser'
import { useMapInteractions } from './map-interactions.hook'
import { MapListSelect, MapSettingsRow } from './map-settings-row.component'
import { MapWithListRow } from './map-with-list-row.component'
import { getFilteredAndSortedUnits, MapGridUnit } from './map-with-list-row.utils'
import UnitCell, { Facility } from './unit-cell.component'
//import iconAtendente from '@root/src/assets/img/iconAtendente.svg'
//import iconsWhatsapp from '@root/src/assets/img/iconsWhatsapp.svg'
//import iconTelefone from '@root/src/assets/img/iconTelefone.svg'
import {
  MaterialGrid,
  UnitsInfoTopPage,
  UnitsInfoTopPageContainer,
  // ContactContainer,
  // LinkToContact,
  // UnitsInfoTopPageContactContainer,
  // UnitsInfoTopPageContactTitle,
  // WhatsappPhoneContainer
} from './map-page.component.style'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import Title from '../../../components/Title'
import InfoContato from '@root/src/components/InfoContato'

interface MapPageProps {
  location: Location
}

export const MapPage: React.FunctionComponent<MapPageProps> = props => {
  const [
    origin,
    userLocation,
    handlePanToUser,
    searchLocation,
    handleSearchSuccess,
    selectedFilter,
    handleFilterSelectionChange
  ] = useMapInteractions()
  const theme = useTheme()

  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))
  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))
  const adjustMarginTop = downOfMd ? 110 : 0

  const [segmentedControlIndex, setSegmentedControlIndex] = useState<MapListSelect>(
    MapListSelect.list
  )
  
  const { value, setTrue, setFalse } = useBooleanState(false)
  const [mapModalOpen, showModal, hideModal] = [value, setTrue, setFalse]

  const resetUI = () => {
    hideModal()
    setSegmentedControlIndex(MapListSelect.list)
  }
  useMatchMediaChanged('(min-width: 64em)', resetUI)

  const [selectedUnitToShowOnMapId, setSelectedUnitToShowOnMapId] = useState<string>()
  const handleSeeUnitOnMap = (id: string) => () => {
    setSelectedUnitToShowOnMapId(id)
    if (isDesktopScreenSize()) {
      setSegmentedControlIndex(MapListSelect.map)
      scrollToTopAnimated()
    } else {
      showModal()
    }
  }

  const handleSeeMapOnMobile = () => {
    showModal()
  }

  return (
    <StaticQuery
      query={graphql`
        query MapPageDetail {
          site {
            siteMetadata {
              siteUrl
              googleMapsKey
            }
          }

          allUnidades {
            nodes {
              id
              idLegado
              nome
              slug
              endereco
              longitude
              latitude
              facilidades {
                facilidade
                icone {
                  url
                  imgix_url
                }
              }
            }
          }

          informacoesContato: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
            metadata {
              telefoneContato
              chatOnlineLink
              agendamentoWhatsappLink
              telefoneContatoMedico
              label_telefone
              label_whatsapp
              chatOnlineLinkMedico
              agendamentoWhatsappLinkMedico
              texto_fale_conosco
              show_whatsapp_contactus
            }
          }

          cosmicjsInformacoesEstaticas(slug: { eq: "unidades" }) {
            id
            title
            metadata {
              unitTitle
              descricaoHTML
              info_atendimento
              entre_em_contato
              horario_de_atendimento
              encontre_nossas_unidades
              unitNotAllowedSubtitle
              unitAllowedSubtitle
              unitSeoDescription
              unitSeeUnitOnMap
              unitSeeUnitDetail
              unitSeoImage {
                url
              }
              unitSeoImageAlt
              unitPin {
                url
                imgix_url
              }
              selectedUnitPin {
                url
                imgix_url
              }
              unitPinHover {
                url
                imgix_url
              }
              userPin {
                url
                imgix_url
              }
              searchPin {
                url
                imgix_url
              }
            }
          }
        }
      `}
      render={(data: Query) => {
        const locationToBeUsed =
          origin === 'search' ? searchLocation && searchLocation.geometry.location : userLocation

        const units: MapGridUnit[] = data.allUnidades.nodes.map(unit => ({
          lat: Number(unit.latitude),
          lng: Number(unit.longitude),
          id: String(unit.idLegado),
          name: unit.nome,
          slug: unit.slug,
          address: unit.endereco,
          to: getUnitDetailPageUrl(
            getPathUrl(appPaths.units.path, isDoctorUrl(props.location.pathname)),
            unit.slug
          ),
          facilities: unit.facilidades
            ? unit.facilidades.map(facility => ({
                facilityName: facility.facilidade,
                iconSrc: facility?.icone?.imgix_url
              }))
            : [],
          distanceInKm:
            locationToBeUsed &&
            getDistanceFromLatLonInKm(
              { lat: Number(unit.latitude), lng: Number(unit.longitude) },
              locationToBeUsed
            )
        }))

        const allFacilidades = units.reduce(
          (acc, curr) => acc.concat(curr.facilities),
          [] as Facility[]
        )
        const uniqueFacilidades = getUniqueFacilities(allFacilidades)

        const filteredAndSortedUnits = getFilteredAndSortedUnits(
          units,
          locationToBeUsed,
          selectedFilter
        )

        const informacoesContato = data.informacoesContato.metadata

        const canonicalUrl = getCanonicalUrl(data.site.siteMetadata.siteUrl, appPaths.units.path)
        return (
          <>
            <SEO
              jsonld={getListJsonLd(
                units.map(item => ({ url: normalizeUrl(`${canonicalUrl}/${item.to}`) }))
              )}
              socialMedia={{
                canonicalUrl,
                title: data.cosmicjsInformacoesEstaticas.title,
                image: data.cosmicjsInformacoesEstaticas.metadata.unitSeoImage.url,
                imageAlt: data.cosmicjsInformacoesEstaticas.metadata.unitSeoImageAlt,
                description: data.cosmicjsInformacoesEstaticas.metadata.unitSeoDescription
              }}
            />
            {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
              <EventTimestampContainer>
                {event => (
                  <>
                    <Container style={{ marginTop: adjustMarginTop}} maxWidth="md">
                      <TitleWithBreadcrumbRow title={data.cosmicjsInformacoesEstaticas.title} />
                      <MaterialGrid container>
                        <MaterialGrid item xs={12}>
                          <UnitsInfoTopPage
                            dangerouslySetInnerHTML={{
                              __html: data.cosmicjsInformacoesEstaticas.metadata.descricaoHTML
                            }}
                          />
                        </MaterialGrid>
                        <MaterialGrid item xs={12} sm={5}>
                          <UnitsInfoTopPageContainer>
                            <UnitsInfoTopPage>
                              {data.cosmicjsInformacoesEstaticas.metadata.info_atendimento}
                            </UnitsInfoTopPage>
                          </UnitsInfoTopPageContainer>
                        </MaterialGrid>
                        {!downOfSm && (
                          <MaterialGrid item sm={1}>
                            <SeparatorWV />
                          </MaterialGrid>
                        )}
                        <MaterialGrid item xs={12} sm={6}>
                          <InfoContato
                            informacoesContato={informacoesContato}
                            horarios={
                              data.cosmicjsInformacoesEstaticas.metadata.horario_de_atendimento
                            }
                            titulo={data.cosmicjsInformacoesEstaticas.metadata.entre_em_contato}
                          />
                        </MaterialGrid>
                        <MaterialGrid item xs={12}>
                          <Title variant="h5">
                            {data.cosmicjsInformacoesEstaticas.metadata.encontre_nossas_unidades}
                          </Title>
                          <MapSettingsRow
                            userLocation={userLocation}
                            mapListSelect={segmentedControlIndex}
                            facilities={uniqueFacilidades}
                            googleMapsKey={data.site.siteMetadata.googleMapsKey}
                            onSearchSuccess={handleSearchSuccess(event.setTimestamp)}
                            onFilterValueChange={handleFilterSelectionChange(event.setTimestamp)}
                            onMapListSegmentedControlChange={setSegmentedControlIndex}
                          />
                        </MaterialGrid>
                      </MaterialGrid>
                    </Container>

                    <GrayLightBackground>
                      <Container maxWidth="md">
                        <Modal opened={mapModalOpen} onClose={hideModal} noPadding={true}>
                          <MapWithListRow
                            cosmicjsMapas={data.cosmicjsInformacoesEstaticas}
                            internalMapsKey={event.timestamp}
                            units={filteredAndSortedUnits}
                            googleMapsKey={data.site.siteMetadata.googleMapsKey}
                            onPanToUserClick={handlePanToUser(event.setTimestamp)}
                            searchLocation={searchLocation && searchLocation.geometry.location}
                            userLocation={userLocation}
                            originLocation={locationToBeUsed}
                            defaultSelectedUnitId={selectedUnitToShowOnMapId}
                          />
                        </Modal>

                        {segmentedControlIndex === MapListSelect.map && (
                          <>
                            <MapWithListRow
                              cosmicjsMapas={data.cosmicjsInformacoesEstaticas}
                              internalMapsKey={event.timestamp}
                              units={filteredAndSortedUnits}
                              googleMapsKey={data.site.siteMetadata.googleMapsKey}
                              onPanToUserClick={handlePanToUser(event.setTimestamp)}
                              searchLocationName={
                                searchLocation && searchLocation.formatted_address
                              }
                              searchLocation={searchLocation && searchLocation.geometry.location}
                              userLocation={userLocation}
                              originLocation={locationToBeUsed}
                              defaultSelectedUnitId={selectedUnitToShowOnMapId}
                            />
                            <LargeSeparatorRow />
                          </>
                        )}

                        {segmentedControlIndex === MapListSelect.list && (
                          <>
                            <Row mb>
                              <Col xs={12}>
                                <H2>
                                  {searchLocation || userLocation
                                    ? data.cosmicjsInformacoesEstaticas.metadata.unitAllowedSubtitle
                                    : data.cosmicjsInformacoesEstaticas.metadata
                                        .unitNotAllowedSubtitle}
                                </H2>

                                {searchLocation && (
                                  <ForcedFade
                                    show={true}
                                    key={searchLocation && searchLocation.formatted_address}
                                  >
                                    <Body>
                                      Exibindo {filteredAndSortedUnits.length} unidades
                                      {` próximas a "${searchLocation.formatted_address}"`}
                                    </Body>
                                  </ForcedFade>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              {filteredAndSortedUnits.map(unit => (
                                <Col xs={12} sm={6} md={4} key={unit.name}>
                                  <UnitCell
                                    unitName={unit.name}
                                    unitAddress={unit.address}
                                    link1Text={
                                      data.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitOnMap
                                    }
                                    link1OnClick={handleSeeUnitOnMap(unit.id)}
                                    link2Text={
                                      data.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitDetail
                                    }
                                    link2To={unit.to}
                                    facilities={unit.facilities}
                                    distanceInKm={unit.distanceInKm}
                                    slug={unit.slug}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <Row>
                              <Col xs={12} sm={false}>
                                <Button
                                  id="button-mobile-see-map"
                                  expanded={true}
                                  kind="secondary"
                                  onClick={handleSeeMapOnMobile}
                                >
                                  Ver mapa
                                </Button>
                              </Col>
                            </Row>
                            <LargeSeparatorRow />
                          </>
                        )}
                      </Container>
                    </GrayLightBackground>
                  </>
                )}
              </EventTimestampContainer>
            ) : (
              <EventTimestampContainer>
                {event => (
                  <>
                    <Grid>
                      <TitleWithBreadcrumbRow title={data.cosmicjsInformacoesEstaticas.title}>
                        <Body>
                          <>
                            <Separator />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.cosmicjsInformacoesEstaticas.metadata.descricaoHTML
                              }}
                            />
                          </>
                        </Body>
                      </TitleWithBreadcrumbRow>

                      <MapSettingsRow
                        userLocation={userLocation}
                        mapListSelect={segmentedControlIndex}
                        facilities={uniqueFacilidades}
                        googleMapsKey={data.site.siteMetadata.googleMapsKey}
                        onSearchSuccess={handleSearchSuccess(event.setTimestamp)}
                        onFilterValueChange={handleFilterSelectionChange(event.setTimestamp)}
                        onMapListSegmentedControlChange={setSegmentedControlIndex}
                      />
                    </Grid>

                    <NeutralBackground>
                      <Modal opened={mapModalOpen} onClose={hideModal} noPadding={true}>
                        <MapWithListRow
                          cosmicjsMapas={data.cosmicjsInformacoesEstaticas}
                          internalMapsKey={event.timestamp}
                          units={filteredAndSortedUnits}
                          googleMapsKey={data.site.siteMetadata.googleMapsKey}
                          onPanToUserClick={handlePanToUser(event.setTimestamp)}
                          searchLocation={searchLocation && searchLocation.geometry.location}
                          userLocation={userLocation}
                          originLocation={locationToBeUsed}
                          defaultSelectedUnitId={selectedUnitToShowOnMapId}
                        />
                      </Modal>

                      {segmentedControlIndex === MapListSelect.map && (
                        <Grid>
                          <MapWithListRow
                            cosmicjsMapas={data.cosmicjsInformacoesEstaticas}
                            internalMapsKey={event.timestamp}
                            units={filteredAndSortedUnits}
                            googleMapsKey={data.site.siteMetadata.googleMapsKey}
                            onPanToUserClick={handlePanToUser(event.setTimestamp)}
                            searchLocationName={searchLocation && searchLocation.formatted_address}
                            searchLocation={searchLocation && searchLocation.geometry.location}
                            userLocation={userLocation}
                            originLocation={locationToBeUsed}
                            defaultSelectedUnitId={selectedUnitToShowOnMapId}
                          />
                          <LargeSeparatorRow />
                        </Grid>
                      )}

                      {segmentedControlIndex === MapListSelect.list && (
                        <Grid>
                          <Row mb>
                            <Col xs={12}>
                              <H2>
                                {searchLocation || userLocation
                                  ? data.cosmicjsInformacoesEstaticas.metadata.unitAllowedSubtitle
                                  : data.cosmicjsInformacoesEstaticas.metadata
                                      .unitNotAllowedSubtitle}
                              </H2>

                              {searchLocation && (
                                <ForcedFade
                                  show={true}
                                  key={searchLocation && searchLocation.formatted_address}
                                >
                                  <Body>
                                    Exibindo {filteredAndSortedUnits.length} unidades
                                    {` próximas a "${searchLocation.formatted_address}"`}
                                  </Body>
                                </ForcedFade>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            {filteredAndSortedUnits.map(unit => (
                              <Col xs={12} sm={6} md={4} key={unit.name}>
                                <UnitCell
                                  unitName={unit.name}
                                  unitAddress={unit.address}
                                  link1Text={
                                    data.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitOnMap
                                  }
                                  link1OnClick={handleSeeUnitOnMap(unit.id)}
                                  link2Text={
                                    data.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitDetail
                                  }
                                  link2To={unit.to}
                                  facilities={unit.facilities}
                                  distanceInKm={unit.distanceInKm}
                                  slug={unit.slug}
                                />
                              </Col>
                            ))}
                          </Row>
                          <Row>
                            <Col xs={12} sm={false}>
                              <Button
                                id="button-mobile-see-map"
                                expanded={true}
                                kind="secondary"
                                onClick={handleSeeMapOnMobile}
                              >
                                Ver mapa
                              </Button>
                            </Col>
                          </Row>
                          <LargeSeparatorRow />
                        </Grid>
                      )}
                    </NeutralBackground>
                  </>
                )}
              </EventTimestampContainer>
            )}
          </>
        )
      }}
    />
  )
}
